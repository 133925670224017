import React from 'react'

import { analytics } from '~/analytics'
import Icon from '~/truck/icon'
import { emailUrl, phoneUrl, whatsappUrl } from '~/utils'

enum ContactType {
  MAIL = 'mail',
  WHATSAPP = 'whatsapp',
  TELEPHONE = 'telephone',
  YOUTUBE = 'youtube',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
}

const Contact = props => {
  const { vendor } = props

  const { facebook, instagram, youtube } = vendor

  const hasSocialNetwork = facebook || instagram || youtube

  const socialMediaTypes = [ContactType.FACEBOOK, ContactType.INSTAGRAM, ContactType.YOUTUBE]

  const onContactClick = (type: ContactType) => {
    if (socialMediaTypes.includes(type)) {
      analytics.socialMedia(type)
    } else {
      analytics.contact(type as ContactType.MAIL | ContactType.TELEPHONE | ContactType.WHATSAPP)
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <>
        <h5 className="font-semibold">Contacto</h5>
        <div className="flex flex-col gap-2">
          {vendor.contactEmail && (
            <a
              type="mail"
              className="break-words"
              href={emailUrl(vendor.contactEmail)}
              rel="nofollow"
              onClick={() => onContactClick(ContactType.MAIL)}
            >
              <span>{vendor.contactEmail}</span>
            </a>
          )}
          {vendor.phone && (
            <a
              type="telephone"
              className="flex gap-2 break-words"
              href={phoneUrl(vendor.phone)}
              rel="nofollow"
              onClick={() => onContactClick(ContactType.TELEPHONE)}
            >
              {<Icon type="telephone" color="black" />}
              <span className="text-xl font-semibold">{vendor.phone}</span>
            </a>
          )}
          {vendor.whatsapp && (
            <a
              type="whatsapp"
              className="flex gap-2 break-words"
              href={whatsappUrl(vendor.phone)}
              rel="noopener noreferrer nofollow"
              target="_blank"
              onClick={() => onContactClick(ContactType.WHATSAPP)}
            >
              {<Icon type="whatsapp" color="black" />}
              <span className="text-xl font-semibold">{vendor.whatsapp}</span>
            </a>
          )}
        </div>
      </>
      {hasSocialNetwork && (
        <>
          <span>Síguenos en:</span>
          <div className="flex gap-2">
            {facebook && (
              <a
                href={facebook}
                type="facebook"
                rel="noopener noreferrer nofollow"
                target="_blank"
                onClick={() => onContactClick(ContactType.FACEBOOK)}
              >
                <Icon type="facebook" color="black" />
              </a>
            )}
            {instagram && (
              <a
                href={instagram}
                type="instagram"
                rel="noopener noreferrer nofollow"
                target="_blank"
                onClick={() => onContactClick(ContactType.INSTAGRAM)}
              >
                <Icon type="instagram" color="black" />
              </a>
            )}
            {youtube && (
              <a
                href={youtube}
                type="youtube"
                rel="noopener noreferrer nofollow"
                target="_blank"
                onClick={() => onContactClick(ContactType.YOUTUBE)}
              >
                <Icon type="youtube" color="black" />
              </a>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default React.memo(Contact)
